<template>
  <div class="TypeBox">
    <div v-for="(item, index) in props.data" :key="index" class="TypeList">
      <template v-if="item.typeList && item.typeList.length > 1">
        <p class="Type">
          <span>{{ item.typeName }}</span>
        </p>
        <p>
          <span
            v-for="(item2, index2) in item.typeList"
            :key="index2"
            :class="{ active: state.indexList[index] === index2 }"
            class="TypeItem"
            @click="ChangeTypeIndex(index, index2, item2)"
            >{{ item2.label }}</span
          >
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
export default {
  name: "FilterType",
  components: {},
  props: ["data"],
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      indexList: {},
    });
    props.data.forEach((item, index) => {
      state.indexList[index] = 0
    })
    const ChangeTypeIndex = (index, index2, item2) => {
      state.indexList[index] = index2;
      emit("GetTypeIndex", index, item2);
    };
    return {
      state,
      props,
      ChangeTypeIndex,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "/src/comment/comment";
.TypeBox {
  background-color: #ececec;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-radius: 4px;
  .TypeList {
    display: flex;
    justify-content: flex-start;
    .Type {
      margin-top: 10px;
      display: inline-block;
      width: 120px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      text-align: left;
      margin-left: 20px;
    }
    > p:nth-child(2) {
      width: calc(100% - 120px);
    }

    .TypeItem {
      display: inline-block;
      cursor: pointer;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-right: 20px;
      margin-top: 10px;
    }

    .active {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #7f0404;
    }
  }
}
</style>