<template>
  <div class="FirstBox">
    <p class="FirstTitle">{{ props.myTitle.ChinaName }}</p>
    <p class="BgTitle">{{ props.myTitle.EnglishName }}</p>
  </div>
</template>

<script>
import * as Vue from "vue";
export default {
  name: "FirstTitle",
  components: {},
  props: ["myTitle"],
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({});
    return {
      state,
      props,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";
.FirstBox {
  position: relative;
  z-index: 10;
  background-color: transparent;
  padding-top: 40px;
  .BgTitle {
    width: 100%;
    text-align: center;
    color: #e7e7e7;
    font-size: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 15px));
    z-index: -1;
    font-weight: bold;
  }
}
</style>